import AppDispatcher from '../Dispatcher/Dispatcher';
import { apiv1 } from '../APIs/API';

export const Stalwarts = (pageno = "", filterby = '') => {
    var data2 = {};
    data2['action'] = "getstalwartlist";
    data2['index'] = pageno = "" ? 0 : pageno;
    data2['order'] = "date%20desc";
    data2['category'] = filterby == '' ? '' : filterby;
    let Inforesponse = apiv1.apiv1(data2)
    Inforesponse.done(function (res2) {
        // NewResponse = res2;
        const resonse = JSON.parse(res2)
        if (resonse._resultflag == "1") {
            // var stalwartsdetails = {}
            // stalwartsdetails = resonse.data
            AppDispatcher("StalwartsStore").dispatch({
                actionType: 'STALWARTS',
                value: resonse.data

            });

        } else if (resonse._resultflag == "0") {
            AppDispatcher("StalwartsStore").dispatch({
                actionType: 'STALWARTS',
                value: {}

            });
        }

    })
    return Inforesponse;
}