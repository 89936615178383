import AppDispatcher from "../Dispatcher/Dispatcher";
import { encryption, apiv1, mlapiv1, apiv2 } from "../APIs/API";
var data = {};
var data2 = {};
var data3 = {};
var NewResponse = "";
var NewResponse3 = "";
export const FeedDetails = (pageNumber = "") => {
  data2["action"] = "myfeedlist";
  // 21/11/2018  -- merflagversion
  data2["merflagversion"] = 1;
  data2["index"] = pageNumber == "" ? 0 : pageNumber;
  var response = apiv2.apiv2(data2);
  response.done(function (res2) {
    NewResponse = res2;
    var resonse = JSON.parse(NewResponse);
    if (resonse._resultflag == "1") {
      var feeddetails = resonse.feedslist;
      var promotion = resonse.promotions_data;
      if (resonse.topmenustack.length > 0) {
        var topmenustack = resonse.topmenustack;
        data = [feeddetails, promotion, topmenustack];
      } else {
        // for page number 2 top menu is not come from api
        data = [feeddetails, promotion];
      }
    } else if (resonse._resultflag == "0") {
      data = "No Record Found";
    }

    AppDispatcher("HomeStore").dispatch({
      actionType: "FEEDLIST",
      value: data,
    });
  });
  return response;
};

export const TrendingList = () => {
  var trendlist;
  data3["action"] = "gettrendingtopics";
  var response = apiv1.apiv1(data3);
  response.done(function (res3) {
    NewResponse3 = res3;
    var resonse = JSON.parse(NewResponse3);
    if (resonse._resultflag == "1") {
      trendlist = resonse.tags;
    }
    AppDispatcher("HomeStore").dispatch({
      actionType: "TRENDINGLIST",
      value: trendlist,
    });
  });
  return response;
};

export const Follow_followlist = () => {
  data["action"] = "followlist";
  data["index"] = "0";
  data["order"] = "date%20desc";
  var followrsponse = mlapiv1.mlapiv1(data);
  followrsponse.done(function (res2) {
    NewResponse = res2;
    var resonse = JSON.parse(NewResponse);
    var DETAIL;
    if (resonse._resultflag == "1") {
      DETAIL = resonse.followlist;
      AppDispatcher("HomeStore").dispatch({
        actionType: "FOLLOWLIST",
        value: DETAIL,
      });
    }
  });
};
// class HomeAction {

//     FeedDetails(pageNumber = "") {
//         data2['action'] = "myfeedlist";
//         // 21/11/2018  -- merflagversion
//         data2['merflagversion'] = 1;
//         data2['index'] = pageNumber == "" ? 0 : pageNumber;

//         var response = apiv2.apiv2(data2)

//         response.done(function (res2) {
//             NewResponse = res2;
//             // var message = {}
//             var resonse = JSON.parse(NewResponse);
//             if (resonse._resultflag == "1") {
//                 var feeddetails = resonse.feedslist
//                 var promotion = resonse.promotions_data
//                 if (resonse.topmenustack.length > 0) {
//                     var topmenustack = resonse.topmenustack
//                     data = [feeddetails, promotion, topmenustack]
//                 } else {
//                     // for page number 2 top menu is not come from api
//                     data = [feeddetails, promotion]
//                 }

//             } else if (resonse._resultflag == "0") {
//                 data = "No Record Found"
//             }

//             AppDispatcher("HomeStore").dispatch({
//                 actionType: 'FEEDLIST',
//                 value: data
//             });

//         })
//         return response;
//     }

//     TrendingList() {
//         var trendlist;
//         data3['action'] = "gettrendingtopics";
//         // data3['index'] = "0";
//         var response = apiv1.apiv1(data3)
//         response.done(function (res3) {
//             NewResponse3 = res3;
//             // var message = {}
//             var resonse = JSON.parse(NewResponse3)
//             if (resonse._resultflag == "1") {
//                 trendlist = resonse.tags
//             }

//             AppDispatcher("HomeStore").dispatch({
//                 actionType: 'TRENDINGLIST',
//                 value: trendlist

//             });
//         })
//         return response;

//     }

//     Follow_followlist() {
//         data['action'] = "followlist";
//         data['index'] = "0";
//         data['order'] = "date%20desc";

//         var followrsponse = mlapiv1.mlapiv1(data)
//         followrsponse.done(function (res2) {
//             NewResponse = res2;
//             var resonse = JSON.parse(NewResponse)
//             var DETAIL;
//             if (resonse._resultflag == "1") {
//                 DETAIL = resonse.followlist

//                 AppDispatcher('HomeStore').dispatch({
//                     actionType: 'FOLLOWLIST',
//                     value: DETAIL

//                 });
//             }
//         })

//     }

// }
// export default new HomeAction()
