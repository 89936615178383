import { createStore } from 'redux';


const Infographicspost = (state, action) => {
    var newState = {};
    //no user
    if (action.type === "POST_DATA111") {
        newState['id'] = action.id;
        newState['likecount'] = action.likecount;
        newState['image'] = action.image;
    } else {
        newState['id'] = null;
        newState['likecount'] = null;
        newState['image'] = null;
    }
    return newState;
}

const InfographicspostStore = createStore(Infographicspost);

const Quotespost = (state, action) => {
    var newState = {};

    if (action.type === "POST_DATA") {
        newState['id'] = action.id;
        newState['likecount'] = action.likecount;
        newState['image'] = action.image;
    } else {
        newState['id'] = null;
        newState['likecount'] = null;
        newState['image'] = null;
    }
    return newState;
}

const quotespostStore = createStore(Quotespost);

const Stalwartspost = (state, action) => {
    var newState = {};

    if (action.type === "POST_DATA") {
        newState['id'] = action.id;
        newState['likecount'] = action.likecount;
        newState['image'] = action.image;
    } else {
        newState['id'] = null;
        newState['likecount'] = null;
        newState['image'] = null;
    }
    return newState;
}

const StalwartspostStore = createStore(Stalwartspost);

const MannKiBaatPlayer = (state, action) => {
    var newState = {};
    if (action.type === "CURRENTPLAY_DETAIL") {
        newState['datalist'] = action.detail;
        newState['currentindex'] = action.currentindex;
    } else {
        newState['datalist'] = "";
        newState['currentindex'] = "";
    }
    return newState;
}

const WatchLiveDetail = (state, action) => {
    var newState = {};
    if (action.type === "WATCHLIVE_PLAY_DETAIL") {
        newState['datalist'] = action.detail;
    } else {
        newState['datalist'] = "";
    }
    return newState;
}
const GovInVideoDetail = (state, action) => {
    var newState = {};
    if (action.type === "GovInVideo") {
        newState['datalist'] = action.detail;
    } else {
        newState['datalist'] = "";
    }
    return newState;
}

const SubCommentList = (state, action) => {
    var newState = {};
    if (action.type === "SUBCOMMENT_LIST") {
        newState['list'] = action.list;
    } else {
        newState['list'] = "";
    }
    return newState;
}

const oauthList = (state, action) => {
    var newState = {};
    if (action.type === "OAUTHTOKEN") {
        newState['Token'] = action.token;
        newState['device_id'] = action.device_id;
    } else {
        newState['Token'] = "";
        newState['device_id'] = "";
    }
    return newState;
}

const useroauthList = (state, action) => {
    var newState = {};
    if (action.type === "USEROAUTHTOKEN") {
        newState['X-Access-Token'] = action.accessToken;
        newState['addressid'] = action.addressid;
    } else {
        newState['X-Access-Token'] = "";
        newState['addressid'] = "";
    }
    return newState;
}

const loginCheckList = (state, action) => {
    var newState = {};
    if (action.type === "CHECKLOGIN") {
        newState['checklogin'] = true;
    } else {
        newState['checklogin'] = false;
    }
    return newState;
}

const profileCheckList = (state, action) => {
    var newState = {};
    if (action.type === "CHECKLOGIN") {
        newState['checklogin'] = true;
    } else {
        newState['checklogin'] = false;
    }
    return newState;
}

const checkGlobLang = (state, action) => {
    var newState = {};
    if (action.type === "CHECKLANG") {
        newState['checkgloblang'] = true;
    } else {
        newState['checkgloblang'] = false;
    }
    return newState;
}

const MannKiBaatPlayerStore = createStore(MannKiBaatPlayer);
const SubCommentListStore = createStore(SubCommentList);
const WatchLiveDetailStore = createStore(WatchLiveDetail);
const GovInVideoDetailStore = createStore(GovInVideoDetail);
const oauthStore = createStore(oauthList);
const useroauthStore = createStore(useroauthList);
const loginCheckStore = createStore(loginCheckList);
const profileCheckStore = createStore(profileCheckList);
const globLangStore = createStore(checkGlobLang);

const RelatedNewsDetail = (state, action) => {

    var newState = {};
    if (action.type === "POST_DATA") {
        newState['id'] = action.id;
        newState['date'] = action.date;
        newState['image'] = action.image;
        newState['title'] = action.title;
        newState['description'] = action.description;
        newState['reader_view_url'] = action.reader_view_url;
        newState['likecount'] = action.likecount;
        newState['islike'] = action.islike;
        newState['sharecount'] = action.sharecount;
        newState['commentcount'] = action.commentcount;
        newState['modulename'] = action.modulename;
        if (action.fullObject) {
            newState['fullObject'] = action.fullObject;
        }

    } else {
        newState['id'] = "";
        newState['date'] = "";
        newState['image'] = "";
        newState['title'] = "";
        newState['reader_view_url'] = "";
        newState['likecount'] = "";
        newState['islike'] = "";
        newState['sharecount'] = "";
        newState['commentcount'] = "";
        newState['modulename'] = "";
    }
    return newState;
}

const Detailwebview = (state, action) => {
    var newState = {};
    if (action.type === 'DETAIL_WEBVIEW') {
        newState["userid"] = action.addressid;
        newState["weburl"] = action.url;
        newState["oldurl"] = action.oldurl;
        newState["title"] = action.title
        newState["likecount"] = action.likecount;
        newState["islike"] = action.islike;
        newState["sharecount"] = action.sharecount;
        newState["commentcount"] = action.commentcount;
        newState["webtype"] = action.type;
    } else {
        newState["userid"] = "";
        newState["weburl"] = "";
        newState["oldurl"] = window.location.origin + "HomePage";
        newState["title"] = "Go Back";
        newState["likecount"] = "";
        newState["islike"] = "";
        newState["sharecount"] = "";
        newState["commentcount"] = "";
        newState["webtype"] = "";
    }
    return newState;
}

const UserDetail = (state, action) => {
    var newState = {};

    if (action.type === "USER_DETAIL") {
        newState['user_avatar'] = action.user_avatar;
        newState['username'] = action.username;
    } else {
        newState['user_avatar'] = null;
        newState['username'] = null;
    }
    return newState;
}

const MobileNumberRegistrationDetail = (state, action) => {
    var newState = {};

    if (action.type === "MOBILE_LOGIN") {
        newState = action;
    } else {
        newState = null;
    }
    return newState;
}

const MobilenumberRegStore = createStore(MobileNumberRegistrationDetail);

const RelatedNewsDetailStore = createStore(RelatedNewsDetail);

const UserDetailStore = createStore(UserDetail);

const DetailWebviewStore = createStore(Detailwebview);

const CompleteProfile = (state, action) => {
    var newState = {};
    //no user
    if (action.type === "POST_PROFILE") {
        newState['name'] = action.name;
        newState['email'] = action.email;
        newState['mobile'] = action.mobile;
        newState['password'] = action.password;

    } else {

        newState['name'] = "";
        newState['email'] = "";
        newState['mobile'] = "";
        newState['password'] = "";

    }
    return newState;
}

const CompleteProfileStore = createStore(CompleteProfile);

const CompleteProfile1 = (state, action) => {
    var newState = {};
    //no user
    if (action.type === "USER_PROFILE") {

        newState['statevalue'] = action.statevalue
        newState['Districtvalue'] = action.Districtvalue

    } else {


        newState['statevalue'] = ""
        newState['Districtvalue'] = ""
    }
    return newState;
}

const CompleteProfileStore1 = createStore(CompleteProfile1);

const Dispatchers = (state, action) => {
    if (state) {
        var newState = state;
    } else {
        newState = {};
    }
    if (action.type === "DISPATCH") {

        newState[action.name] = action.dispatcher
    } else {


        newState['name'] = ""
    }
    return newState;
}

const DispatchersStore = createStore(Dispatchers);


const CurrentIndex = (state, action) => {
    var newState = {};
    if (action.type === "CURRENT_INDEX") {
        newState['title'] = action.title
    } else {
        newState['title'] = ""
    }
    return newState;
}

const CurrentIndexStore = createStore(CurrentIndex);


const Comment = (state, action) => {
    if (state) {
        var newState = state;
    } else {
        newState = {};
    }
    if (action.type === "COMMENT_LIST") {
        newState[action.commentid_type] = action.data
    }
    return newState;
}

const CommentsStore = createStore(Comment);

const TrendSearch = (state, action) => {
    var newState = {};
    if (action.type === "TREND_SEARCH") {
        newState['title'] = action.title
    } else {
        newState['title'] = ""
    }
    return newState;
}

const TrendSearchStore = createStore(TrendSearch);

const ShareDataV2 = (state, action) => {

    let newState = {};
    if (action.type === "SHARE_ITEM") {
        newState = { ...action };
    } else {
        newState = null
    }

    return newState;
}

const Donationlogin = (state, action) => {
    var newState = {};
    if (action.type === "DONATION_LOGIN") {
        newState['phoneno'] = action.phoneno;
        newState['encnumber'] = action.encnumber;
    } else {
        newState['phoneno'] = ""
        newState['encnumber'] = ""
    }

    return newState;

}
const DonationloginStore = createStore(Donationlogin);

const DonationloginUserinfo = (state, action) => {
    var newState = {};
    if (action.type === "DONATION_LOGIN_RESPONSE") {
        newState['referral_code'] = action.referral_code;
        newState['referral_name'] = action.referral_name;
        newState['referral_url'] = action.referral_url;
        newState['encmobileno'] = action.encmobileno;
    } else {
        newState['referral_code'] = "";
        newState['referral_name'] = "";
        newState['referral_url'] = "";
        newState['encmobileno'] = "";
    }

    return newState;

}

const DetailPageData = (state, action) => {
    var newState = {};
    if (action.type === "DETAIL_PAGE_STORE") {
        newState = action
    } else {
        newState = null
    }

    return newState;
}

const SetDataforVerify = (state, action) => {
    let newState = {};
    if (action.type === "SET_ENCDATA") {
        newState = action

    } else {
        newState = null
    }
    return newState;

}

const SetDataStories = (state, action) => {
    let newState = {};
    if (action.type === "SET_STORIES_STACK") {
        newState = action
    } else {
        newState = null
    }
    return newState;

}


const setdataforvikasYatra = (state, action) => {
    let newState = {};
    if (action.type === "SET_VERSION") {
        newState = action;
    } else {
        newState = null;
    }
    return newState;
}
const ShareData = (state, action) => {
    var newState = {};
    if (action.type === "SHAREDATA") {
        newState['data'] = action.data;
        newState['modulename'] = action.modulename;
        newState['detailLink'] = action.detailLink;
        newState['sharetext'] = action.sharetext;
        newState['id'] = action.id ? action.id : "";
    } else {
        newState['data'] = null;
        newState['modulename'] = null;
        newState['detailLink'] = null;
        newState['sharetext'] = null;
        newState['id'] = null;
    }
    return newState;
}
const ShareDataExternal = (state, action) => {
    var newState = {};

    if (action.type === "SHAREDATAEXTERNAL") {
        newState['data'] = action.data;
        newState['modulename'] = action.modulename;
        newState['detailLink'] = action.detailLink;
        newState['sharetext'] = action.sharetext;
        newState['id'] = action.id ? action.id : "";
    } else {
        newState['data'] = null;
        newState['modulename'] = null;
        newState['detailLink'] = null;
        newState['sharetext'] = null;
        newState['id'] = null;
    }
    return newState;
}
// const Detailwebview = (state, action) => {

//     var newState = {};
//     if (action.type == 'DETAIL_WEBVIEW') {
//         newState["userid"] = action.addressid;
//         newState["weburl"] = action.url;
//         newState["oldurl"] = action.oldurl;
//         newState["title"] = action.title
//         newState["webtype"] = action.type;
//     } else {
//         newState["userid"] = "";
//         newState["weburl"] = "";
//         newState["oldurl"] = window.location.origin;
//         newState["title"] = "Go Back";
//         newState["webtype"] = "";
//     }
//     return newState;
// }
const ObjetStoreCT = (state, action) => {

    var newState = {};
    if (action.type === "CTEVENT") {
        newState['cardid'] = action.id;
        newState['title'] = action.title;
        newState['category'] = action.category;
        newState['objectdata'] = action.objectdata ? action.objectdata : ""
    } else {
        newState['cardid'] = "";
        newState['title'] = "";
        newState['category'] = "";
    }
    return newState;

}

const CtpageEventdata = (state, action) => {

    var newState = {};
    if (action.type === "PAGEEVENTDATA") {
        newState['objectdata'] = action.objectdata ? action.objectdata : ""
    } else {
        newState['objectdata'] = "";
    }
    return newState;

}
const Images = (state, action) => {
    var newState = {};
    if (action.type === "IMAGE") {
        newState['image_url'] = action.image_url;
        newState['images'] = action.images;
        newState['title'] = action.title;
        newState['img_type'] = action.img_type;

    } else {
        newState['image_url'] = "";
        newState['images'] = "";
        newState['title'] = '';
        newState['img_type'] = '';

    }
    return newState;
}

const storeStateData = (state, action) => {
    var newState = {};
    if (action.type === "SURVEYSTATEDATA") {
        newState['data'] = action.data;
    } else {
        newState['data'] = {
            "user_state_id": "",
            "users_constituency_id": "",
            "user_state": "",
            "users_constituency": ""
        };
    }
    return newState;
}

const StoresurveyStateData = createStore(storeStateData);
const ImagesStore = createStore(Images);
const CtpageEventdataStore = createStore(CtpageEventdata);
const ObjetStoreCTStore = createStore(ObjetStoreCT);
const ShareDataStore = createStore(ShareData);
const ShareDataExternalStore = createStore(ShareDataExternal);

const DonationloginUserinfoStore = createStore(DonationloginUserinfo);

const ShareDataV2Store = createStore(ShareDataV2);

const DetailPageDataStore = createStore(DetailPageData);

const SetDataforVerifyStore = createStore(SetDataforVerify);
const SetDataStoriesStore = createStore(SetDataStories);
const SetdataforvikasYatraStore = createStore(setdataforvikasYatra);

export default InfographicspostStore;




export {

    InfographicspostStore,
    quotespostStore,
    StalwartspostStore,
    MannKiBaatPlayerStore,
    SubCommentListStore,
    WatchLiveDetailStore,
    GovInVideoDetailStore,
    oauthStore,
    useroauthStore,
    loginCheckStore,
    profileCheckStore,
    globLangStore,
    RelatedNewsDetailStore,
    UserDetailStore,
    DetailWebviewStore,
    CompleteProfileStore,
    CompleteProfileStore1,
    MobilenumberRegStore,
    CurrentIndexStore,
    CommentsStore,
    DonationloginStore,
    DonationloginUserinfoStore,
    TrendSearchStore,
    ShareDataV2Store,
    DetailPageDataStore,
    SetDataforVerifyStore,
    SetDataStoriesStore,
    SetdataforvikasYatraStore,
    ShareDataStore,
    ShareDataExternalStore,
    ObjetStoreCTStore,
    CtpageEventdataStore,
    ImagesStore,
    StoresurveyStateData
}