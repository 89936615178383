import AppDispatcher from '../Dispatcher/Dispatcher';
import { encryption, apiv2, mlapiv1 } from '../APIs/API';
import { setStorageItem } from '../Utils/Helper';

var data2 = {};
var NewResponse = '';
var data3 = {};
var NewResponse3 = '';
var data4 = {};
var NewResponse4 = '';
var data5 = {};
var NewResponse5 = '';
var data6 = {};
var NewResponse6 = '';

export const GovtMicroIn = (pageNumber = "", filterby, session) => {
    data2['action'] = "getgovmicroalldata";
    data2['index'] = pageNumber === "" ? 0 : pageNumber;
    // data2['session'] = session;
    if (typeof (filterby) != "undefined") {
        data2['filterby'] = typeof (filterby) === "undefined" ? "" : filterby;
    }
    var ref = this;
    var Inforesponse = apiv2.apiv2(data2)
    Inforesponse.done(function (res2) {
        NewResponse = res2;
        var message = {}
        var resonse = JSON.parse(NewResponse)
        if (resonse._resultflag === "1") {
            var infodetails = {}
            infodetails = resonse.feedslist

        } else {
            infodetails = {}
        }
        AppDispatcher("GovtInActionMicroStore").dispatch({
            actionType: 'GOVTINACTIONMICRO',
            value: infodetails


        });
    })
    return Inforesponse;
}
export const Articles = (pageNumber = "", filterby = "", session) => {
    var ref = this;
    data3['action'] = "getgovmicronews";
    data3['index'] = pageNumber === "" ? 0 : pageNumber;
    // data3['session'] = session;
    if (typeof (filterby) != "undefined") {
        data3['filterby'] = typeof (filterby) === "undefined" ? "" : filterby;
    }
    var response = apiv2.apiv2(data3);
    response.done(function (res3) {
        NewResponse3 = res3;
        var message = {}
        var resonse3 = JSON.parse(NewResponse3)

        var details3 = {}
        if (resonse3._resultflag === "1") {

            details3 = resonse3.newslist

        } else {
            details3 = {}
        }
        AppDispatcher("GovtInActionMicroStore").dispatch({
            actionType: 'ARTICLES',
            value: details3
        });
        if (pageNumber === "" || pageNumber === 0) {
            setStorageItem('getarticles_list', details3);
        }
    })
    return response;

}
export const Infographics = (pageNumber = "", filterby = "", session) => {
    data4['action'] = "getgovmicroinfo";
    data4['index'] = pageNumber === "" ? 0 : pageNumber;
    // data4['session'] = session;
    if (typeof (filterby) != "undefined") {
        data4['filterby'] = typeof (filterby) === "undefined" ? "" : filterby;
    }
    var response = apiv2.apiv2(data4)
    response.done(function (res) {
        NewResponse4 = res;
        var message = {}
        var resonse4 = JSON.parse(NewResponse4)

        var details4 = {}
        if (resonse4._resultflag === "1") {

            details4 = resonse4.list

        } else {
            details4 = {}
        }
        AppDispatcher("GovtInActionMicroStore").dispatch({
            actionType: 'INFOGRAPHICS',
            value: details4


        });
        if (pageNumber === "" || pageNumber === 0) {
            setStorageItem('getinfographics_list', details4);
        }
    })
    return response;

}
export const MediaCoverage = (pageNumber = "", filterby = "", session) => {
    data5['action'] = "getgovmicromedia";
    data5['index'] = pageNumber === "" ? 0 : pageNumber;
    //data5['session'] = session;
    if (typeof (filterby) != "undefined") {
        data5['filterby'] = typeof (filterby) === "undefined" ? "" : filterby;
    }
    var response = apiv2.apiv2(data5)
    response.done(function (res) {
        NewResponse5 = res;
        var message = {}
        var resonse5 = JSON.parse(NewResponse5)

        var details5 = {}
        if (resonse5._resultflag === "1") {

            details5 = resonse5.mediacoveragelist

        } else {
            details5 = {}
        }
        AppDispatcher("GovtInActionMicroStore").dispatch({
            actionType: 'MEDIACOVERAGE',
            value: details5
        });
        if (pageNumber === "" || pageNumber === 0) {
            setStorageItem('getmediacoverage_list', details5);
        }
    })
    return response;

}
export const Video = (pageNumber = "", filterby = "", session) => {
    data6['action'] = "getgovmicrovideo";
    data6['index'] = pageNumber === "" ? 0 : pageNumber;
    // data6['session'] = session;
    if (typeof (filterby) != "undefined") {
        data6['filterby'] = typeof (filterby) === "undefined" ? "" : filterby;
    }
    var response = apiv2.apiv2(data6)
    response.done(function (res) {
        NewResponse6 = res;
        var message = {}
        var resonse6 = JSON.parse(NewResponse6)

        var details6 = {}
        if (resonse6._resultflag === "1") {

            details6 = resonse6.newslist

        } else {
            details6 = {}
        }
        AppDispatcher("GovtInActionMicroStore").dispatch({
            actionType: 'VIDEO',
            value: details6


        });
        if (pageNumber === "" || pageNumber === 0) {
            setStorageItem('getvideos_list', details6);
        }
    })
    return response;

}

export const ArticlesNewFilter = (pageNumber = "", filterby = "", category) => {
    if (category == "articles") {
        Articles(pageNumber, filterby);
    } else if (category == "graphics") {
        Infographics(pageNumber, filterby);
    } else if (category == "videos") {
        Video(pageNumber, filterby);
    } else if (category == "media-coverage") {
        MediaCoverage(pageNumber, filterby);
    }
    //             var ref = this;
    //     data3['action'] = "getgovmicrovideo";
    //     data3['index'] = pageNumber === "" ? 0 : pageNumber;
    //    // data3['session'] = session;
    //     if (typeof (filterby) != "undefined") {
    //         data3['filterby'] = typeof (filterby) === "undefined" ? "" : filterby;
    //     }
    //     var response = apiv2.apiv2(data3);
    //     response.done(function (res) {
    //         NewResponse6 = res;
    //         var message = {}
    //         var resonse6 = JSON.parse(NewResponse6)

    //         var details6 = {}
    //         if (resonse6._resultflag === "1") {

    //             details6 = resonse6.newslist

    //         } else {
    //             details6 = {}
    //         }
    //         AppDispatcher("GovtInActionMicroStore").dispatch({
    //             actionType: 'VIDEO',
    //             value: details6
    //         });
    //     })
    //     return response;

}

