import React from "react";
import moment from "moment/moment";
import $ from "jquery";

import interval from '../Utils/Datefunction';
import { encryption } from "../APIs/API";
import { oauthStore, loginCheckStore } from "../Redux/ReduxStore";
// import Hindi from "../Language/Hindi";

import Bootstrap from "./Bootstrap";
import CleverTap from "./Clevertap";
import { process } from "./Language";
import CleverTapSdk from "./CleverTapSdk";
import { badge } from "./Badge";

export const time = "09:59";
export const onClickHandler = (url) => {
  window.open(url, "_blank");
}
export const stateList = [
  { "statename": "Andaman and Nicobar Islands" },
  { "statename": "Andhra Pradesh" },
  { "statename": "Arunachal Pradesh" },
  { "statename": "Assam" },
  { "statename": "Bihar" },
  { "statename": "Chhattisgarh" },
  { "statename": "Chandigarh" },
  { "statename": "Dadra and Nagar Haveli" },
  { "statename": "Daman and Diu" },
  { "statename": "Delhi" },
  { "statename": "Goa" },
  { "statename": "Gujarat" },
  { "statename": "Haryana" },
  { "statename": "Himachal Pradesh" },
  { "statename": "Jharkhand" },
  { "statename": "Jammu and Kashmir" },
  { "statename": "Karnataka" },
  { "statename": "Kerala" },
  { "statename": "Lakshadweep" },
  { "statename": "Ladakh" },
  { "statename": "Madhya Pradesh" },
  { "statename": "Maharashtra" },
  { "statename": "Manipur" },
  { "statename": "Meghalaya" },
  { "statename": "Mizoram" },
  { "statename": "Nagaland" },
  { "statename": "Odisha" },
  { "statename": "Punjab" },
  { "statename": "Puducherry" },
  { "statename": "Rajasthan" },
  { "statename": "Sikkim" },
  { "statename": "Tamil Nadu" },
  { "statename": "Telangana" },
  { "statename": "Tripura" },
  { "statename": "Uttarakhand" },
  { "statename": "Uttar Pradesh " },
  { "statename": "West Bengal" },
]

export const loginalertbox = (message) => {
  $("#event_alert_box").show();
  $("#alert_box_text_msg").text(message);
  return true;
};

export const dateReformat = (dateraw) => {
  //"2018-29-01T02:50:41+05:30"    === to ===  29January 2018
  let dateFormat = dateraw;
  try {
    dateFormat = dateFormat.split("T");
    if (typeof dateFormat === "undefined") {
      return dateraw;
    }
    var dataReformat = dateFormat[0].split("-");
    dateFormat =
      dataReformat[0] + "-" + dataReformat[2] + "-" + dataReformat[1];
    dateFormat = moment(dateFormat).format("LL");
  } catch (e) { }

  if (dateFormat == 'Invalid date') {
    let datesplit = dateraw.split(" ");
    dateFormat = moment(datesplit[0]).format("LL");
  }

  return dateFormat;
};

export const dateReadable = (d) => {
  let formatedDate = d.split(' ')[0];
  return moment(formatedDate).format("LL")
}


export const genrateguid = () => {
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
};

function s4() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}

export const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
};

export const startTimer = () => {
  if (document.getElementById("timer")) {
    var presentTime = document.getElementById("timer").innerHTML;
    var timeArray = presentTime.split(/[:]+/);
    var m = timeArray[0];
    var s = checkSecond(timeArray[1] - 1);
    if (s === 59) {
      m = m - 1;
    }
    if (m < 0) {
      var element = document.getElementById("verify-event");
      if (element) {
        element.classList.add("disablebtn");
      }
      return false;
    }

    document.getElementById("timer").innerHTML = m + ":" + s;
    if (m === 0) {
      document.getElementById("min").innerHTML = " sec";
    }
    setTimeout(startTimer, 1000);
  }
  return false;
};

function checkSecond(sec) {
  if (sec < 10 && sec >= 0) {
    sec = "0" + sec;
  } // add zero in front of numbers < 10
  if (sec < 0) {
    sec = "59";
  }
  return sec;
}

export const validateYouTubeUrl = (url) => {
  var url = url;
  if (url !== undefined || url !== "") {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
    var match = url.match(regExp);
    if (match && match[2].length === 11) {
      // Do anything for being valid
      // if need to change the url to embed url then use below line
      return true;
    } else {
      return false;
      // Do anything for not being valid
    }
  }
};

export const genrateoauthToken = (params) => {
  var data = {};
  data["data"] = JSON.stringify({ Token: params });
  data["method"] = "forward";
  data["deviceid"] = params;
  var apidata = encryption.encrypted(data);
  apidata.done(function (response) {
    var Res = JSON.parse(response);
    //    set token in store:
    var data = { type: "OAUTHTOKEN", token: Res.Token, device_id: params };
    oauthStore.dispatch(data);
  });
};

export const getStorageItem = (item) => {
  try {
    return JSON.parse(window.localStorage.getItem(item));
  } catch (ex) {
    return null;
  }
};

export const setStorageItem = (item, value) => {
  return window.localStorage.setItem(item, JSON.stringify(value));
};

export const generatedecryptionkey = (item) => {
  var deviceid = localStorage.getItem("_userdevice");
  var data2 = {};
  data2["data"] = JSON.stringify(item);
  data2["deviceid"] = deviceid;
  data2["method"] = "backward";
  var token = encryption.encrypted(data2);
  return token;
};

export const SetlanguageGlobal = (lang) => {
  var Mkblang = JSON.parse(localStorage.getItem("language"));
  var detailObj = {};
  detailObj["global_lang"] = lang;
  detailObj["mkb_lang"] = Mkblang["mkb_lang"];
  window.localStorage.setItem("language", JSON.stringify(detailObj));

  try {
    localStorage.removeItem("myfeedlist_feeddetails");
    localStorage.removeItem("gettrendingtopics_trendlist");
    localStorage.removeItem("getnewslist_newsdetails");
    localStorage.removeItem("getinfographicslistll_infodetails");
    localStorage.removeItem("getmissiontlistll_missionlist");
    localStorage.removeItem("mywalllist_walllist");
    localStorage.removeItem("mywalllist_postwalllist");
    localStorage.removeItem("mywalllist_newswalllist");
    localStorage.removeItem("getallgovernanceglobal_infodetails");
    localStorage.removeItem("getbiographylist_list");
    localStorage.removeItem("getblogslist_list");
    localStorage.removeItem("geteditorslist_list");
    localStorage.removeItem("getglobalrecognitionlist_list");
    localStorage.removeItem("getgovernancelist_details");
    localStorage.removeItem("getgovtinfographics_details");
    localStorage.removeItem("getinterviewslist_list");
    localStorage.removeItem("getmannkibaatplaylist_list");
    localStorage.removeItem("getmediacoveragelist_list");
    localStorage.removeItem("getquoteslist_list");
    localStorage.removeItem("getspeecheslist_list");
    localStorage.removeItem("myfeedlist_promotion");
    localStorage.removeItem("speeches_detailObj");
  } catch (e) { }
  Bootstrap.ActionsCall(0);
};

export const Capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const clevertapTrackevent = (data) => {
  /**
   * e_n : event ka name |  "Page Viewed"
   * s_s_n : evet sub screen name
   * e_d : event data
   */
  var eventData = {};

  let evtName = "Page Viewed";

  if (data["s_n"]) {
    eventData["Screen Name"] = data["s_n"];
  }
  if (data["s_s_n"]) {
    eventData["Sub Screen Name"] = data["s_s_n"];
  }

  let eventInfo = {
    evtName: evtName,
    evtData: eventData,
  };
  // CleverTap.trackEvent(eventInfo);
};
export const text = (text) => {
  var language = "en";
  if (localStorage.getItem("language")) {
    var language = JSON.parse(localStorage.getItem("language"))[
      "global_lang"
    ];
    // language = localStorage.getItem("language");
  }
  return process(language, text);
}
export const aes256 = (item) => { }
export const badgeIcon = (key) => {
  if (!Array.isArray(key) && typeof key === "string" && badge[key]) {
    var kdss = badge[key];
    return badge[key];
  }
}
export const checkLogin = () => {
  if (!localStorage.getItem("_authdetail")) {
    // $("#login_popup_box").css("display", "block");
    loginCheckStore.dispatch({ 'type': 'CHECKLOGIN' });

    if (
      document.referrer.indexOf("localhost") !== -1 ||
      document.referrer.indexOf("firebaseapp") !== -1 ||
      document.referrer.indexOf("narendramodi") !== -1 ||
      true
    ) {
      window.history.go(-1); //if its previos page was frmo within
    }
  }
}
export const getUrlParameter = (sParam) => {
  var results = new RegExp("[/#?&]" + sParam + "=([^&#]*)").exec(
    window.location.href
  );
  if (results === null) {
    return null;
  } else {
    return decodeURI(results[1]) || 0;
  }
}

export const checkLoginLink = (e, redirecturl, reload = false) => {
  // this function genrate pupup if user already login then return true else false
  if (!localStorage.getItem("_authdetail")) {
    // $("#login_popup_box").css("display", "block");
    loginCheckStore.dispatch({ 'type': 'CHECKLOGIN' });

    if (redirecturl) {
      localStorage.setItem("redirecturl", redirecturl);
    }
    if (reload) {
      localStorage.setItem("redirecturl", true);
    }
    // if (e) {
    //   e.preventDefault();
    // }
    return false;
  } else {
    return true;
  }
}

export const showLoader = () => {
  $(".preloader_mantra").show();
}

export const hideLoader = () => {
  $(".preloader_mantra").hide();
}

export const alertbox = (message) => {
  $("#alert_box").show();
  $("#alert_box_text_msg").text(message);
  return true;
}

export const customLoginBox = (e, message, redirect) => {
  if (!localStorage.getItem("_authdetail")) {
    $("#peoplepulse_alert_box_text").text(message);
    localStorage.setItem("redirecturl", redirect);
    $("#peoplepulse_login_alert").show();
    if (e) {
      e.preventDefault();
    }
    return false;
  } else {
    return true;
  }
}

export const commonImageError = (e) => {
  var image = e.target;
  if (image.processed !== 1) {
    setTimeout(function () {
      image.src = image.getAttribute("data-img-src");
      image.processed = 1;
    }, 0);
  } else if (image && image.src.indexOf("/1.png") === "-1") {
    if (image.processed === 1) {
      image.src = "/assets/images/1.png";
    }
  }
}

export const placeholderimage = (image) => {
  if (image.processed !== 1) {
    setTimeout(function () {
      image.src = image.getAttribute("data-img-src");
      image.processed = 1;
    }, 0);
  } else if (image && image.src.indexOf("/1.png") === "-1") {
    if (image.processed === 1) {
      image.src = "/assets/images/1.png";
    }
  }
}

export const parseURLParams = (url) => {
  var queryStart = url.indexOf("?") + 1,
    queryEnd = url.indexOf("#") + 1 || url.length + 1,
    query = url.slice(queryStart, queryEnd - 1),
    pairs = query.replace(/\+/g, " ").split("&"),
    parms = {},
    i,
    n,
    v,
    nv;

  if (query === url || query === "") return;

  for (i = 0; i < pairs.length; i++) {
    nv = pairs[i].split("=", 2);
    n = decodeURIComponent(nv[0]);
    v = decodeURIComponent(nv[1]);

    if (!parms.hasOwnProperty(n)) parms[n] = [];
    parms[n].push(nv.length === 2 ? v : null);
  }
  return parms;
}

export const image = (image_src) => {
  try {
    // if (image_src) {
    if (
      image_src.indexOf("http") === -1 &&
      image_src.indexOf("base64") === -1
    ) {
      image_src = "https://cdn.narendramodi.in/" + image_src;
    }
    var httpsImage = Bootstrap.image + image_src.replace("http:", "https:");

    return httpsImage;
    // }else{
    //   console.log("image not found",image_src)
    // }
  } catch (e) {
    console.log("catch", e)
  }
}

export const userImage = (image_src) => {
  try {
    if (image_src) {
      if (
        image_src.indexOf("http") === -1 &&
        image_src.indexOf("base64") === -1
      ) {
        image_src = "https://cdn.narendramodi.in/" + image_src;
      }
      var httpsImage = Bootstrap.image + image_src.replace("http:", "https:");
      let count = 0;
      if (!(httpsImage.indexOf('cdn.') !== -1)) {
        let imgExtArr = ['.apng', '.bmp', '.gif', '.ico', '.cur', '.jpg', '.jpeg', '.jfif', '.pjpeg', '.pjp', '.png', '.svg', '.tif', '.tiff', '.webp']
        for (let i = 0; i < imgExtArr.length; i++) {
          const img = imgExtArr[i];
          if (httpsImage.indexOf(img) !== -1) {
            count += 1;
          }
        }
        if (count == 0) {
          return noImgUserPlaceholder();
        }
      }
      return httpsImage;
    }
  } catch (e) { }
}

export const checkoffline = () => {
  if (navigator.onLine === true) {
    localStorage.setItem("online_flag", "1");
  } else {
    localStorage.setItem("online_flag", "0");
  }

  window.addEventListener(
    "online",
    function () {
      // alert("connected")

      localStorage.setItem("online_flag", "1");
    },
    false
  );

  window.addEventListener(
    "offline",
    function () {
      //alert("No connection")

      localStorage.setItem("online_flag", "0");
    },
    false
  );
}

export const userProfiledata = (data) => {
  var prfiledata = localStorage.getItem("userdata")
    ? localStorage.getItem("userdata")
    : data;

  var deviceId = localStorage.getItem("_userdevice");
  var UserDetail = localStorage.getItem("_authdetail");

  try {
    var kk = {};

    // this case for update profile , create profle
    var profileData = JSON.parse(prfiledata);
    var UserDetail = JSON.parse(UserDetail);
    kk = profileData[0];
    kk["from_pwa"] = 1;
    kk["pwa_device"] = getMobileOperatingSystem();

    // CleverTap.insertUserProfile(kk, UserDetail.userid, deviceId);
  } catch (ex) {
    // this case for Login
    prfiledata["from_pwa"] = 1;
    prfiledata["pwa_device"] = getMobileOperatingSystem();

    // CleverTap.insertUserProfile(prfiledata, prfiledata.userid, deviceId);
    return null;
  }
}

export const cleavertapeventforLogin = (data, eventname, type) => {
  var eventData = {};
  eventData["evtName"] = "Login";
  eventData["evtData"] = data;
  eventData["type"] = type;
  // CleverTap.trackEvent(eventData);
  // CleverTapSdk.handleProfleTracking(data);
}

export const chkNetwork = (e, index) => {
  if (localStorage.getItem("online_flag") === 1) {
    return true;
  } else if (localStorage.getItem("online_flag") === 0) {
    $("#alert_network").css("display", "block");
    $("#hiddenInput").val(index);
    e.preventDefault();

    return false;
  } else {
    return true;
  }
}

export const getLastText = (url) => {
  if (url) {
    var url = url.split("/");

    return url[url.length - 1];
  } else {
    return url;
  }
}

export const parseDate = (s) => {
  var months = {
    jan: 0,
    feb: 1,
    mar: 2,
    apr: 3,
    may: 4,
    jun: 5,
    jul: 6,
    aug: 7,
    sep: 8,
    oct: 9,
    nov: 10,
    dec: 11,
  };
  var p = s.split("-");
  return new Date(p[2], months[p[1].toLowerCase()], p[0]);
}
export const redirect = (history, redirecturl, open) => {
  if (redirecturl.indexOf("volunteer") !== -1) {
    window.location.href = redirecturl;
  } else if (redirecturl.indexOf("nation-builder") !== -1) {
    window.location.href = redirecturl;
  } else {
    history.push(redirecturl);
  }
}
export const validURL = (str) => {
  var pattern = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
  if (!pattern.test(str)) {
    var status = "false";
  } else {
    var status = "true";
  }
  return status;
}

export const getDevicetype = () => {
  if (navigator.userAgent.match(/Android/i)) {
    return "Android";
  } else if (navigator.userAgent.match(/BlackBerry/i)) {
    return "BlackBerry";
  } else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
    return "iOS";
  } else if (navigator.userAgent.match(/Opera Mini/i)) {
    return "Opera";
  } else if (navigator.userAgent.match(/IEMobile/i)) {
    return "Windows";
  } else {
    return "Android";
  }
}
export const checkPhoneNumber = (phonenumber) => {
  try {
    var regex = /^\d{10}$/;
    var num = [];
    var splitnum = [];
    var newcontact;
    if (phonenumber.match(regex)) {
      phonenumber = "+91" + phonenumber;
      newcontact = phonenumber;
    } else {
      var count = phonenumber.replace(/\D/g, "").length;
      if (count > 9) {
        for (var i = count + 1; i >= count - 8; i--) {
          num.push(phonenumber[i]);
        }
        for (var i = 0; i <= count - 9; i++) {
          splitnum.push(phonenumber[i]);
        }
      }
      var newnum = num.reverse().join("");
      splitnum = splitnum.join("").trim();
      if (splitnum.includes("+")) {
      } else {
        splitnum = "+" + splitnum;
      }
      newcontact = splitnum + newnum;
    }
    return newcontact;
  } catch (e) {
    return phonenumber;
  }
}
export const filter = (data) => {
  var eventData = {
    evtName: "Filter",
    pg: 1,
    evtData: {
      "Screen Name": data.screen_name,
      "Sub Screen Name": data.sub_screen_name,
      category: data.category,
    },
  };
  // CleverTap.trackEvent(eventData);
}
export const trendingCrossClieckCT = (data) => {
  var eventData = {
    evtName: "Trending Cross Click",
    pg: 1,
    evtData: {
      "Screen Name": data.screen_name,
      "Sub Screen Name": data.sub_screen_name,
      Carousel: data.carousel,
    },
  };
  // CleverTap.trackEvent(eventData);
}

export const textLimit = (value, limit) => {
  try {
    let result = value.slice(0, limit);
    if (value.length > limit) {
      result = result + "...";
    }
    return result;
  } catch (e) {
    return value;
  }
}

export const kFormatter = (num) => {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
    : Math.sign(num) * Math.abs(num);
}

// export const changeHeaderSize=()=> {
//   $("nav.navbar").css("min-height", "85px");
//   $("nav.navbar").css('padding', '15px 0px 0px 0px');
//   $(".navbar").css('margin-bottom', '0px');
// }

// export const unChangeHeaderSize=()=> {
//   $("nav.navbar").css("min-height", "164px");
//   $("nav.navbar").css('padding', '40px 0px 0px 0px');
//   $(".navbar").css('margin-bottom', '20px');
// }

export const hideHeader = () => {
  $("#navbar_menu").css("display", "none");
}

export const hideSubHeader = () => {
  $("#submenul1").css("display", "none");
  $("#submenul2").css("display", "none");
}

export const hideFooter = () => {
  $("#footer_content").css("display", "none");
}

export const showHeader = () => {
  $("#navbar_menu").css("display", "block");
}

export const showSubHeader = () => {
  $("#submenul1").css("display", "block");
  $("#submenul2").css("display", "block");
}

export const showFooter = () => {
  $("#footer_content").css("display", "block");
}

export const hideHeaderFooter = () => {
  $("#navbar_menu").css("display", "none");
  $("#footer_content").css("display", "none");
}

export const showHeaderFooter = () => {
  $("#navbar_menu").css("display", "block");
  $("#footer_content").css("display", "block");
}

export const convertToSlug = (Text) => {
  if (Text && typeof Text != "undefined") {
    return Text.toLowerCase()
      .replace(/[^\w ]+/g, "")
      .replace(/ +/g, "-");
  }
}

export const removeLocalStorageData = () => {
  try {
    localStorage.removeItem("myfeedlist_feeddetails");
    localStorage.removeItem("gettrendingtopics_trendlist");
    localStorage.removeItem("getnewslist_newsdetails");
    localStorage.removeItem("getinfographicslistll_infodetails");
    localStorage.removeItem("getmissiontlistll_missionlist");
    localStorage.removeItem("mywalllist_walllist");
    localStorage.removeItem("mywalllist_postwalllist");
    localStorage.removeItem("mywalllist_newswalllist");
    localStorage.removeItem("getallgovernanceglobal_infodetails");
    localStorage.removeItem("getbiographylist_list");
    localStorage.removeItem("getblogslist_list");
    localStorage.removeItem("geteditorslist_list");
    localStorage.removeItem("getglobalrecognitionlist_list");
    localStorage.removeItem("getgovernancelist_details");
    localStorage.removeItem("getgovtinfographics_details");
    localStorage.removeItem("getinterviewslist_list");
    localStorage.removeItem("getmannkibaatplaylist_list");
    localStorage.removeItem("getmediacoveragelist_list");
    localStorage.removeItem("getquoteslist_list");
    localStorage.removeItem("getspeecheslist_list");
    localStorage.removeItem("myfeedlist_promotion");
    localStorage.removeItem("speeches_detailObj");
  } catch (e) { }
};

export const getProfessionList = () => {
  return [
    "Artist",
    "Agriculture",
    "Architect/Construction",
    "Banking & Financial services",
    "Chartered Accountant",
    "Communications",
    "Community Service",
    "Defence Forces/Security",
    "Doctor/Medical Services",
    "Engineering",
    "Govt. & PSU",
    "Home-Maker",
    "Hospitality",
    "Human Resources",
    "IT Services",
    "Law",
    "Logistics",
    "Manufacturing",
    "Marketing & Sales",
    "Shipping/Port Management",
    "Student",
    "Teaching/Training",
    "Telecom Services",
  ];
};
export const getInterestList = () => {
  return [
    "Agriculture",
    "Animal Husbandry",
    "Arts & Culture",
    "Art & Entertainment",
    "Defense",
    "Economy & Finance",
    "Education",
    "Environment",
    "Healthcare",
    "Spirituality",
    "Technology",
    "Tourism",
    "Women Empowerment",
  ];
};
export const pauseAllVideos = (e) => {
  //     $('iframe').contents().find('video').each(function ()
  //     {
  //         this.pause();
  //     });
  //     $('video').each(function ()
  //     {
  //         this.pause();
  //     });
};
export const titleCase = (str) => {
  if (str) {
    var splitStr = str.toLowerCase().split("-");
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
  }
};

export const numberFormat = (num) => {
  num = num.toString();
  var lastThree = num.substring(num.length - 3);
  var otherNumbers = num.substring(0, num.length - 3);
  if (otherNumbers != "") lastThree = "," + lastThree;
  var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
  return res;
}

export const bytesToSize = (bytes) => {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes == 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
}

export const getGoogleMapApiKey = () => {
  return "AIzaSyAljVcaYgkugi40_at4DcNtI7Ohk50TITE";
}
export const handleCtEvent = (data) => {
  var evtData = {};
  var eventData = {};
  evtData['Card ID'] = data['cardid'] ? data['cardid'] : "";
  evtData['Title'] = data['title'] ? data['title'] : "";
  evtData['Category'] = data['category'] ? data['category'] : "";

  eventData['evtData'] = evtData;
  // CleverTapSdk.handleEventtracking(eventData, "Card Click");
}
export const CrossClickSurvey = (data) => {
  var evtData = {};
  var eventData = {};
  evtData['Screen Name'] = data.screen_name ? data.screen_name : '';
  evtData['Category'] = data.category ? data.category : "";
  eventData['evtData'] = evtData;
  // CleverTapSdk.handleEventtracking(eventData, "Cross Walk");
}
export const IMWIDTH = () => {
  var param = {}
  param.five0 = 50;
  param.one00 = 100;
  param.three00 = 300;
  param.six00 = 600;
  return param
}


export const lParam = () => {
  var param = {}
  param.lazyheight = 300
  param.lazytype = "once"
  return param
}

export const noImgCardPlaceholder = () => {
  return "/assets/img/noimage.jpg";
}

export const noImgUserPlaceholder = () => {
  // return "/assets/img/user-icon-placeholder.jpg";
  return "https://cdn.narendramodi.in/cmsuploads/0.10215000_1709614972_user-icon-placeholder.jpg";
}

export const pastDateTimeFormat = (datetime) => {
  let newTime = "";
  if (JSON.parse(interval(new Date(moment(datetime)), new Date(moment().format("MM-DD-YYYY HH:MM:SS")))).days > 0) {
    newTime = moment(datetime).format("MMMM DD,YYYY")
  } else {
    if (JSON.parse(interval(new Date(moment(datetime)), new Date(moment().format("MM-DD-YYYY HH:MM:SS")))).hours) {
      newTime = JSON.parse(interval(new Date(moment(datetime)), new Date(moment().format("MM-DD-YYYY HH:MM:SS")))).hours
        + " hours ago";
    }
  }
  return newTime;
}

// class Helper extends React.Component{
  // text(text) {
  //   var language = "en";
  //   if (localStorage.getItem("language")) {
  //     var language = JSON.parse(localStorage.getItem("language"))[
  //       "global_lang"
  //     ];
  //   }
  //   return Language.process(language, text);
  // }

  // aes256(item) { }
  // badgeIcon(key) {
  //   if (!Array.isArray(key) && typeof key === "string" && badge[key]) {
  //     var kdss = badge[key];
  //     return badge[key];
  //   }
  // }

  // checkLogin() {
  //   if (!localStorage.getItem("_authdetail")) {
  //     $("#login_popup_box").css("display", "block");
  //     if (
  //       document.referrer.indexOf("localhost") !== -1 ||
  //       document.referrer.indexOf("firebaseapp") !== -1 ||
  //       document.referrer.indexOf("narendramodi") !== -1 ||
  //       true
  //     ) {
  //       window.history.go(-1); //if its previos page was frmo within
  //     }
  //   }
  // }

  // getUrlParameter(sParam) {
  //   var results = new RegExp("[/#?&]" + sParam + "=([^&#]*)").exec(
  //     window.location.href
  //   );
  //   if (results === null) {
  //     return null;
  //   } else {
  //     return decodeURI(results[1]) || 0;
  //   }
  // }

  // used
  // checkLoginLink(e, redirecturl) {
  //   if (!localStorage.getItem("_authdetail")) {
  //     $("#login_popup_box").css("display", "block");
  //     if (redirecturl) {
  //       localStorage.setItem("redirecturl", redirecturl);
  //     }
  //     if (e) {
  //       e.preventDefault();
  //     }
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }

  // showLoader() {
  //   $(".preloader_mantra").show();
  // }

  // hideLoader() {
  //   $(".preloader_mantra").hide();
  // }

  // alertbox(message) {
  //   $("#alert_box").show();
  //   $("#alert_box_text_msg").text(message);
  //   return true;
  // }

  // customLoginBox(e, message, redirect) {
  //   if (!localStorage.getItem("_authdetail")) {
  //     $("#peoplepulse_alert_box_text").text(message);
  //     localStorage.setItem("redirecturl", redirect);
  //     $("#peoplepulse_login_alert").show();
  //     if (e) {
  //       e.preventDefault();
  //     }
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }

  // commonImageError(e) {
  //   var image = e.target;
  //   if (image.processed !== 1) {
  //     setTimeout(function () {
  //       image.src = image.getAttribute("data-img-src");
  //       image.processed = 1;
  //     }, 0);
  //   } else if (image && image.src.indexOf("/1.png") === "-1") {
  //     if (image.processed === 1) {
  //       image.src = "/assets/images/1.png";
  //     }
  //   }
  // }

  // placeholderimage(image) {
  //   if (image.processed !== 1) {
  //     setTimeout(function () {
  //       image.src = image.getAttribute("data-img-src");
  //       image.processed = 1;
  //     }, 0);
  //   } else if (image && image.src.indexOf("/1.png") === "-1") {
  //     if (image.processed === 1) {
  //       image.src = "/assets/images/1.png";
  //     }
  //   }
  // }

  // parseURLParams(url) {
  //   var queryStart = url.indexOf("?") + 1,
  //     queryEnd = url.indexOf("#") + 1 || url.length + 1,
  //     query = url.slice(queryStart, queryEnd - 1),
  //     pairs = query.replace(/\+/g, " ").split("&"),
  //     parms = {},
  //     i,
  //     n,
  //     v,
  //     nv;

  //   if (query === url || query === "") return;

  //   for (i = 0; i < pairs.length; i++) {
  //     nv = pairs[i].split("=", 2);
  //     n = decodeURIComponent(nv[0]);
  //     v = decodeURIComponent(nv[1]);

  //     if (!parms.hasOwnProperty(n)) parms[n] = [];
  //     parms[n].push(nv.length === 2 ? v : null);
  //   }
  //   return parms;
  // }

  // image(image_src) {
  //   try {
  //     if (image_src) {
  //       if (
  //         image_src.indexOf("http") === -1 &&
  //         image_src.indexOf("base64") === -1
  //       ) {
  //         image_src = "https://cdn.narendramodi.in/" + image_src;
  //       }
  //       var httpsImage = Bootstrap.image + image_src.replace("http:", "https:");

  //       return httpsImage;
  //     }
  //   } catch (e) { }
  // }

  // checkoffline() {
  //   if (navigator.onLine === true) {
  //     localStorage.setItem("online_flag", "1");
  //   } else {
  //     localStorage.setItem("online_flag", "0");
  //   }

  //   window.addEventListener(
  //     "online",
  //     function () {
  //       // alert("connected")

  //       localStorage.setItem("online_flag", "1");
  //     },
  //     false
  //   );

  //   window.addEventListener(
  //     "offline",
  //     function () {
  //       //alert("No connection")

  //       localStorage.setItem("online_flag", "0");
  //     },
  //     false
  //   );
  // }

  // userProfiledata(data) {
  //   var prfiledata = localStorage.getItem("userdata")
  //     ? localStorage.getItem("userdata")
  //     : data;

  //   var deviceId = localStorage.getItem("_userdevice");
  //   var UserDetail = localStorage.getItem("_authdetail");

  //   try {
  //     var kk = {};

  //     // this case for update profile , create profle
  //     var profileData = JSON.parse(prfiledata);
  //     var UserDetail = JSON.parse(UserDetail);
  //     kk = profileData[0];
  //     kk["from_pwa"] = 1;
  //     kk["pwa_device"] = getMobileOperatingSystem();

  //     CleverTap.insertUserProfile(kk, UserDetail.userid, deviceId);
  //   } catch (ex) {
  //     // this case for Login
  //     prfiledata["from_pwa"] = 1;
  //     prfiledata["pwa_device"] = getMobileOperatingSystem();

  //     CleverTap.insertUserProfile(prfiledata, prfiledata.userid, deviceId);
  //     return null;
  //   }
  // }

  // cleavertapeventforLogin(data, eventname, type) {
  //   var eventData = {};
  //   eventData["evtName"] = "Login";
  //   eventData["evtData"] = data;
  //   eventData["type"] = type;
  //   CleverTap.trackEvent(eventData);
  //   CleverTapSdk.handleProfleTracking(data);
  // }

  // chkNetwork(e, index) {
  //   if (localStorage.getItem("online_flag") === 1) {
  //     return true;
  //   } else if (localStorage.getItem("online_flag") === 0) {
  //     $("#alert_network").css("display", "block");
  //     $("#hiddenInput").val(index);
  //     e.preventDefault();

  //     return false;
  //   } else {
  //     return true;
  //   }
  // }

  // getLastText(url) {
  //   if (url) {
  //     var url = url.split("/");

  //     return url[url.length - 1];
  //   } else {
  //     return url;
  //   }
  // }

  // parseDate(s) {
  //   var months = {
  //     jan: 0,
  //     feb: 1,
  //     mar: 2,
  //     apr: 3,
  //     may: 4,
  //     jun: 5,
  //     jul: 6,
  //     aug: 7,
  //     sep: 8,
  //     oct: 9,
  //     nov: 10,
  //     dec: 11,
  //   };
  //   var p = s.split("-");
  //   return new Date(p[2], months[p[1].toLowerCase()], p[0]);
  // }
  // redirect(history, redirecturl, open) {
  //   if (redirecturl.indexOf("volunteer") !== -1) {
  //     window.location.href = redirecturl;
  //   } else if (redirecturl.indexOf("nation-builder") !== -1) {
  //     window.location.href = redirecturl;
  //   } else {
  //     history.push(redirecturl);
  //   }
  // }
  // validURL(str) {
  //   var pattern = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
  //   if (!pattern.test(str)) {
  //     var status = "false";
  //   } else {
  //     var status = "true";
  //   }
  //   return status;
  // }

  // getDevicetype() {
  //   if (navigator.userAgent.match(/Android/i)) {
  //     return "Android";
  //   } else if (navigator.userAgent.match(/BlackBerry/i)) {
  //     return "BlackBerry";
  //   } else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
  //     return "iOS";
  //   } else if (navigator.userAgent.match(/Opera Mini/i)) {
  //     return "Opera";
  //   } else if (navigator.userAgent.match(/IEMobile/i)) {
  //     return "Windows";
  //   } else {
  //     return "Android";
  //   }
  // }
  // checkPhoneNumber(phonenumber) {
  //   try {
  //     var regex = /^\d{10}$/;
  //     var num = [];
  //     var splitnum = [];
  //     var newcontact;
  //     if (phonenumber.match(regex)) {
  //       phonenumber = "+91" + phonenumber;
  //       newcontact = phonenumber;
  //     } else {
  //       var count = phonenumber.replace(/\D/g, "").length;
  //       if (count > 9) {
  //         for (var i = count + 1; i >= count - 8; i--) {
  //           num.push(phonenumber[i]);
  //         }
  //         for (var i = 0; i <= count - 9; i++) {
  //           splitnum.push(phonenumber[i]);
  //         }
  //       }
  //       var newnum = num.reverse().join("");
  //       splitnum = splitnum.join("").trim();
  //       if (splitnum.includes("+")) {
  //       } else {
  //         splitnum = "+" + splitnum;
  //       }
  //       newcontact = splitnum + newnum;
  //     }
  //     return newcontact;
  //   } catch (e) {
  //     return phonenumber;
  //   }
  // }
  // filter(data) {
  //   var eventData = {
  //     evtName: "Filter",
  //     pg: 1,
  //     evtData: {
  //       "Screen Name": data.screen_name,
  //       "Sub Screen Name": data.sub_screen_name,
  //       category: data.category,
  //     },
  //   };
  //   // CleverTap.trackEvent(eventData);
  // }
  // trendingCrossClieckCT(data) {
  //   var eventData = {
  //     evtName: "Trending Cross Click",
  //     pg: 1,
  //     evtData: {
  //       "Screen Name": data.screen_name,
  //       "Sub Screen Name": data.sub_screen_name,
  //       Carousel: data.carousel,
  //     },
  //   };
  //   CleverTap.trackEvent(eventData);
  // }

  // textLimit(value, limit) {
  //   try {
  //     let result = value.slice(0, limit);
  //     if (value.length > limit) {
  //       result = result + "...";
  //     }
  //     return result;
  //   } catch (e) {
  //     return value;
  //   }
  // }

  // kFormatter(num) {
  //   return Math.abs(num) > 999
  //     ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
  //     : Math.sign(num) * Math.abs(num);
  // }

  // // changeHeaderSize() {
  // //   $("nav.navbar").css("min-height", "85px");
  // //   $("nav.navbar").css('padding', '15px 0px 0px 0px');
  // //   $(".navbar").css('margin-bottom', '0px');
  // // }

  // // unChangeHeaderSize() {
  // //   $("nav.navbar").css("min-height", "164px");
  // //   $("nav.navbar").css('padding', '40px 0px 0px 0px');
  // //   $(".navbar").css('margin-bottom', '20px');
  // // }

  // hideHeader() {
  //   $("#navbar_menu").css("display", "none");
  // }

  // hideSubHeader() {
  //   $("#submenul1").css("display", "none");
  //   $("#submenul2").css("display", "none");
  // }

  // hideFooter() {
  //   $("#footer_content").css("display", "none");
  // }

  // showHeader() {
  //   $("#navbar_menu").css("display", "block");
  // }

  // showSubHeader() {
  //   $("#submenul1").css("display", "block");
  //   $("#submenul2").css("display", "block");
  // }

  // showFooter() {
  //   $("#footer_content").css("display", "block");
  // }

  // hideHeaderFooter() {
  //   $("#navbar_menu").css("display", "none");
  //   $("#footer_content").css("display", "none");
  // }

  // showHeaderFooter() {
  //   $("#navbar_menu").css("display", "block");
  //   $("#footer_content").css("display", "block");
  // }

  // convertToSlug(Text) {
  //   if (Text && typeof Text != "undefined") {
  //     return Text.toLowerCase()
  //       .replace(/[^\w ]+/g, "")
  //       .replace(/ +/g, "-");
  //   }
  // }

  // removeLocalStorageData = () => {
  //   try {
  //     localStorage.removeItem("myfeedlist_feeddetails");
  //     localStorage.removeItem("gettrendingtopics_trendlist");
  //     localStorage.removeItem("getnewslist_newsdetails");
  //     localStorage.removeItem("getinfographicslistll_infodetails");
  //     localStorage.removeItem("getmissiontlistll_missionlist");
  //     localStorage.removeItem("mywalllist_walllist");
  //     localStorage.removeItem("mywalllist_postwalllist");
  //     localStorage.removeItem("mywalllist_newswalllist");
  //     localStorage.removeItem("getallgovernanceglobal_infodetails");
  //     localStorage.removeItem("getbiographylist_list");
  //     localStorage.removeItem("getblogslist_list");
  //     localStorage.removeItem("geteditorslist_list");
  //     localStorage.removeItem("getglobalrecognitionlist_list");
  //     localStorage.removeItem("getgovernancelist_details");
  //     localStorage.removeItem("getgovtinfographics_details");
  //     localStorage.removeItem("getinterviewslist_list");
  //     localStorage.removeItem("getmannkibaatplaylist_list");
  //     localStorage.removeItem("getmediacoveragelist_list");
  //     localStorage.removeItem("getquoteslist_list");
  //     localStorage.removeItem("getspeecheslist_list");
  //     localStorage.removeItem("myfeedlist_promotion");
  //     localStorage.removeItem("speeches_detailObj");
  //   } catch (e) { }
  // };

  // getProfessionList = () => {
  //   return [
  //     "Artist",
  //     "Agriculture",
  //     "Architect/Construction",
  //     "Banking & Financial services",
  //     "Chartered Accountant",
  //     "Communications",
  //     "Community Service",
  //     "Defence Forces/Security",
  //     "Doctor/Medical Services",
  //     "Engineering",
  //     "Govt. & PSU",
  //     "Home-Maker",
  //     "Hospitality",
  //     "Human Resources",
  //     "IT Services",
  //     "Law",
  //     "Logistics",
  //     "Manufacturing",
  //     "Marketing & Sales",
  //     "Shipping/Port Management",
  //     "Student",
  //     "Teaching/Training",
  //     "Telecom Services",
  //   ];
  // };
  // getInterestList = () => {
  //   return [
  //     "Agriculture",
  //     "Animal Husbandry",
  //     "Arts & Culture",
  //     "Art & Entertainment",
  //     "Defense",
  //     "Economy & Finance",
  //     "Education",
  //     "Environment",
  //     "Healthcare",
  //     "Spirituality",
  //     "Technology",
  //     "Tourism",
  //     "Women Empowerment",
  //   ];
  // };
  // pauseAllVideos = (e) => {
  //   //     $('iframe').contents().find('video').each(function ()
  //   //     {
  //   //         this.pause();
  //   //     });
  //   //     $('video').each(function ()
  //   //     {
  //   //         this.pause();
  //   //     });
  // };
  // titleCase = (str) => {
  //   var splitStr = str.toLowerCase().split("-");
  //   for (var i = 0; i < splitStr.length; i++) {
  //     // You do not need to check if i is larger than splitStr length, as your for does that for you
  //     // Assign it back to the array
  //     splitStr[i] =
  //       splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  //   }
  //   // Directly return the joined string
  //   return splitStr.join(" ");
  // };

  // numberFormat(num) {
  //   num = num.toString();
  //   var lastThree = num.substring(num.length - 3);
  //   var otherNumbers = num.substring(0, num.length - 3);
  //   if (otherNumbers != "") lastThree = "," + lastThree;
  //   var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
  //   return res;
  // }

  // bytesToSize(bytes) {
  //   var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  //   if (bytes == 0) return "0 Byte";
  //   var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  //   return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  // }

  // getGoogleMapApiKey() {
  //   return "AIzaSyAljVcaYgkugi40_at4DcNtI7Ohk50TITE";
  // }
// }
// export default new Helper();
