import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { genrateguid, genrateoauthToken, checkoffline } from "./Utils/Helper";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { DataLoader } from "../src/Views/Common/DataLoader/DataLoader";
const AppRoutes = lazy(() => (import('./Router/Router')));

if (!localStorage.getItem("language")) {

    var langStore = {};
    langStore["global_lang"] = "en";
    langStore["mkb_lang"] = "hindi";
    langStore = JSON.stringify(langStore);
    window.localStorage.setItem("language", langStore);
}

checkoffline();

if (localStorage.getItem("_userdevice") == null) {
    //    set  new userdevice
    const _userdevice = genrateguid();
    localStorage.setItem("_userdevice", _userdevice);
    window.localStorage.setItem("language", langStore);
    genrateoauthToken(_userdevice);
} else {
    //    handle old userdevice
    const _userdevice = localStorage.getItem("_userdevice");
    // if exist
    if (localStorage.getItem("language") == null) {
        window.localStorage.setItem("language", langStore);
    }
    genrateoauthToken(_userdevice);
}

// initialize Firebase 

// Bootstrap.initializeFirebase();
// Bootstrap.ActionsCall(5000);

ReactDOM.render(<Suspense fallback={<DataLoader />}><Router><AppRoutes /></Router></Suspense>, document.getElementById('container'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();