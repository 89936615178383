import moment from 'moment';

const interval = (date1, date2) => {
        if (date2 == "Invalid Date") {
    
            date2 = new Date(moment());
            if (date1 > date2) { // swap
                var result = interval(date2, date1);
                result.years = -result.years;
                result.months = -result.months;
                result.days = -result.days;
                result.hours = -result.hours;

                return result;
            }
            result = {
                years: date2.getYear() - date1.getYear(),
                months: date2.getMonth() - date1.getMonth(),
                days: date2.getDate() - date1.getDate(),
                hours: date2.getHours() - date1.getHours(),
                mins : date2.getMinutes() - date1.getMinutes()
            };

    
            // return false
            if (result.hours < 0) {
                result.days--;
                result.hours += 24;
            }
            if (result.days < 0) {
                result.months--;
                // days = days left in date1's month, 
                //   plus days that have passed in date2's month
                var copy1 = new Date(date1.getTime());
                copy1.setDate(32);
                result.days = 32 - date1.getDate() - copy1.getDate() + date2.getDate();
            }
            if (result.months < 0) {
                result.years--;
                result.months += 12;
            }
            var data = JSON.stringify(result);
    
            return data;
    
        }
    
        else {
            if (date1 > date2) { // swap
                var result = interval(date2, date1);
                result = JSON.parse(result);
                result.years = -result.years;
                result.months = -result.months;
                result.days = -result.days;
                result.hours = -result.hours;
                return result;

            }
            result = {
                years: date2.getYear() - date1.getYear(),
                months: date2.getMonth() - date1.getMonth(),
                days: date2.getDate() - date1.getDate(),
                hours: date2.getHours() - date1.getHours(),
                mins : date2.getMinutes() - date1.getMinutes()
            };
            if (result.mins < 0) {
                result.hours--;
                result.mins += 60;
            }
            // return false
            if (result.hours < 0) {
                result.days--;
                result.hours += 24;
            }
            if (result.days < 0) {
                result.months--;
                // days = days left in date1's month, 
                //   plus days that have passed in date2's month
                var copy1 = new Date(date1.getTime());
                copy1.setDate(32);
                result.days = 32 - date1.getDate() - copy1.getDate() + date2.getDate();
            }
            if (result.months < 0) {
                result.years--;
                result.months += 12;
            }
            var data = JSON.stringify(result);
            return data;
    
        }
    
    }
    export default interval